export interface IGlobalProvider {
  environmentVariableSystem: Environment;
  blockedSystemNotPaid: IBlockedSystem;
  messagePhoneSupport: string;
  employeeLogged: IEmployeeLogged;
  tour: ITour;
  userIsSuperAdmin: boolean;
  controlSavePayrollPaymentMethods: IControlSavePayrollPaymentMethods;
  setControlSavePayrollPaymentMethods: React.Dispatch<
    React.SetStateAction<IControlSavePayrollPaymentMethods>
  >;
  setUserIsSuperAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  setEnvironmentVariableSystem: React.Dispatch<
    React.SetStateAction<Environment>
  >;
  setBlockedSystemNotPaid: React.Dispatch<React.SetStateAction<IBlockedSystem>>;
  setMessagePhoneSupport: React.Dispatch<React.SetStateAction<string>>;
  setEmployeeLogged: React.Dispatch<React.SetStateAction<IEmployeeLogged>>;
  setTour: React.Dispatch<React.SetStateAction<ITour>>;
}

export interface IBlockedSystem {
  blockedSystem: boolean;
  alertSystem: boolean;
  parameterToGet: string;
}

export interface IEmployeeLogged {
  id: number;
  fedexId: string;
  fullName: string;
}

interface IStepTour {
  target: string;
  content: string | JSX.Element;
  title: string;
  placement?: string;
  disableBeacon: boolean;
}

export interface ITour {
  startTour: boolean;
  run: boolean;
  stepIndex: number;
  steps: IStepTour[];
}

export enum Environment {
  PROD = 0,
  DEV = 1,
  HML = 2,
  QAS = 3,
  DEMO = 4,
}

export interface IControlSavePayrollPaymentMethods {
  savePayrollPaymentMethods: boolean;
  saveHourlyWeeklySalary: boolean;
  saveLinehaul: boolean;
  saveShuttle: boolean;
  saveSpot: boolean;
  saveGuaranteedMinimum: boolean;
  savePayrollParametersAux: boolean;
  saveOrganization: boolean;
}
